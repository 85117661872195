/* Header */
.header-border {
  border-bottom: 3px solid #dfe5ed;
}
/* Custom css for displaying a checkbox as a button */

#upload-toggle {
  opacity: 0;
  position: absolute;
}
div#file-upload {
  display: none;
}
#upload-toggle:checked ~ div#smart-capture {
  display: none;
}
#upload-toggle:checked ~ div#file-upload {
  display: inline-block;
}

/* File drop */

#file-drop.dragover {
  border-color: black;
}

/* Blur */

.bg-blur {
  backdrop-filter: blur(5px);
}

/* Elipses */
.loading {
  position: relative;
}
.loading:after {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1600ms infinite;
  animation: ellipsis steps(4, end) 1600ms infinite;
  content: '';
  margin-left: 0.1em;
}

@keyframes ellipsis {
  0%, 20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80%, 100% {
    content: '';
  }
}

@-webkit-keyframes ellipsis {
  0%, 20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80%, 100% {
    content: '';
  }
}

/* So that backgrounds don't cut off half way through the page */

html,
body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  background-color: #eff2f6;
}

/* Liveness */

#align-text {
  height: 17%;
  font-size: 1.4rem;
}

#pose-text {
  background: rgba(73, 144, 226, 0.9);
}

.liveness-top {
  top: 13%;
  left: 0;
  right: 0;
}

/* Filesystem */

#add-button {
  min-width: 200px;
}

#file-drop:hover #add-button {
  background-color: #f5ae56;
}

#file-preview:hover #remove-file {
  background-color: #e74e6a;
}

#id-icon {
  width: 130px;
}

#id-back-icon {
  width: 100px;
}

#face-icon {
  height: 80px;
}

#countdown-text {
  font-size: 8em;
  top: 31%;
}

@media screen and (min-width: 1024px) {
  #countdown-text {
    top: 25%;
  }
}

/* Fonts */

@font-face {
  font-family: 'sofia_probold';
  src: url('./fonts/sofiaprobold-webfont.eot');
  src: url('./fonts/sofiaprobold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/sofiaprobold-webfont.woff2') format('woff2'),
    url('./fonts/sofiaprobold-webfont.woff') format('woff'),
    url('./fonts/sofiaprobold-webfont.ttf') format('truetype'),
    url('./fonts/sofiaprobold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mb-glyph';
  src: url('./fonts/mb-glyph-041217.eot');
  src: url('./fonts/mb-glyph-041217.eot#iefix') format('embedded-opentype'),
    url('./fonts/mb-glyph-041217.woff2') format('woff2'),
    url('./fonts/mb-glyph-041217.woff') format('woff'),
    url('./fonts/mb-glyph-041217.ttf') format('truetype'),
    url('./fonts/mb-glyph-041217.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
