h1,
h2,
h3,
h4,
h5 {
  margin: 5px 0;
}

p {
  margin: 8px auto;
}

select {
  display: block;
  margin: 8px auto;
  width: 100%;
  height: 44px;
  padding: 8px;
}

.section-title {
  margin: 8px auto;
  padding-bottom: 4px;
  border-bottom: 1px solid black;
}

.image-preview,
.camera-viewfinder,
.cropper {
  display: block;
  margin: 8px auto;
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

.button-container {
  display: flex;
  align-items: stretch;
  margin: 8px auto;
  width: 100%;
}

.button {
  -webkit-appearance: none;
  height: 44px;
  flex: 1;
  border: 0px solid transparent;
  background: silver;
  border-radius: 4px;
  box-shadow: 2px 2px 3px silver;
  cursor: pointer;
  outline: none;
  font-weight: bold;
}

.button:active {
  box-shadow: 1px 1px 2px grey;
}

.button:disabled {
  opacity: 0.5;
}

.button:not(:last-child) {
  margin-right: 4px;
}

.button:not(:first-child) {
  margin-left: 4px;
}

.button.button--primary {
  color: white;
  background: #1ea2dd;
}

.button.button--secondary {
  color: white;
  background: grey;
}

.input--container {
}

.input--label {
  display: block;
  margin: 8px auto;
}

.input {
  border: 1px solid silver;
  border-color: #ccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  display: block;
  height: 34px;
  width: 100%;
  padding: 8px;
}

.login-error {
  color: red;
}

.file-input--container {
  display: none;
}

.file-input--alternatives {
  display: block;
  cursor: pointer;
  width: 100%;
  padding: 20px;
  line-height: 44px;
  border: 2px dotted silver;
  text-align: center;
}

.file-input--alternatives.dragover {
  border-color: black;
}

.camera-condition {
  display: inline-block;
  padding: 5px;
  border-radius: 4px;
  background: grey;
  color: white;
}

.documents {
}

.document {
  border: 1px solid silver;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.info-item {
  margin: 5px 0;
}

.info-item__name {
  font-weight: bold;
}

.info-item__name:after {
  content: ':';
}

.info-item__value {
}

.document__details {
  border-bottom: 1px solid silver;
}

.document__extracted-fields {
}

.extracted-field {
}
